export const ArrowRight = ({
    size = "24",
    color = "currentColor",
    style,
    className,
}: {
    size?: string;
    color?: string;
    style?: React.CSSProperties;
    className?: string;
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.99997 11.8936C2.99997 12.4187 3.42415 12.8445 3.94733 12.8445H17.7696L13.4766 17.1649C13.1071 17.5369 13.1078 18.1388 13.4782 18.5096C13.6632 18.6948 13.9053 18.7872 14.1473 18.7872C14.3901 18.7872 14.6331 18.694 14.8181 18.5079L20.7232 12.5651C21.0919 12.1938 21.0919 11.5934 20.7232 11.2221L14.8181 5.27931C14.4486 4.90753 13.8489 4.90682 13.4782 5.27765C13.1078 5.64848 13.1071 6.25036 13.4766 6.62238L17.7696 10.9428H3.94733C3.42415 10.9428 2.99997 11.3685 2.99997 11.8936Z"
            fill={color}
        />
    </svg>
);
