import { CSSProperties } from "react";

export const Chevron = ({
    size = "24",
    color = "currentColor",
    style,
    className,
}: {
    size?: string;
    color?: string;
    style?: CSSProperties;
    className?: string;
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={className}
    >
        <path
            d="M19.765 8.21359C19.6907 8.14589 19.6024 8.09217 19.5053 8.05552C19.4081 8.01887 19.3039 8 19.1987 8C19.0935 8 18.9893 8.01887 18.8921 8.05552C18.7949 8.09217 18.7067 8.14589 18.6324 8.21359L11.9998 14.2436L5.3672 8.2136C5.21701 8.07708 5.0133 8.00038 4.80089 8.00038C4.58848 8.00038 4.38477 8.07708 4.23457 8.2136C4.08438 8.35011 4 8.53527 4 8.72834C4 8.9214 4.08438 9.10656 4.23457 9.24308L11.4335 15.7864C11.5078 15.8541 11.596 15.9078 11.6932 15.9445C11.7904 15.9811 11.8946 16 11.9998 16C12.105 16 12.2092 15.9811 12.3064 15.9445C12.4035 15.9078 12.4918 15.8541 12.5661 15.7864L19.765 9.24308C19.8395 9.17554 19.8986 9.09531 19.9389 9.00698C19.9792 8.91866 20 8.82397 20 8.72834C20 8.63271 19.9792 8.53801 19.9389 8.44969C19.8986 8.36136 19.8395 8.28113 19.765 8.21359Z"
            fill={color}
        />
    </svg>
);
