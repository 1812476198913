"use client";

import React, { useEffect } from "react";
import { Portal } from "movestic-ui/components/Portal";
import { Switch } from "movestic-ui/tailwind/Switch";
import { CookieConsentProvider, useCookieConsentContext } from "./CookieConsentContext";
import { H4, H5 } from "movestic-ui/tailwind/Texts";
import { PrimaryButton, SecondaryButton } from "movestic-ui/tailwind/Buttons";
import Link from "next/link";

export const CookieConsent: React.FunctionComponent = () => (
    <CookieConsentProvider>
        <CookieConsentContainer />
    </CookieConsentProvider>
);

export const CookieConsentChangeButton: React.FunctionComponent = () => {
    const handleShowCookieConsent = () => {
        window.postMessage("showCookieConsent");
    };

    return (
        <PrimaryButton onClick={handleShowCookieConsent} className="mt-10">
            Ändra samtycke
        </PrimaryButton>
    );
};

const CookieConsentContainer: React.FunctionComponent = () => {
    const { initCookieConsent, disposeCookieConsent, showPopup } = useCookieConsentContext();

    useEffect(() => {
        initCookieConsent();
        return () => {
            disposeCookieConsent();
        };
    }, []);

    return (
        <>
            {showPopup && (
                <Portal>
                    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 grid items-center w-screen h-full bg-black/50 justify-items-center">
                        <CookieConsentComponent />
                    </div>
                </Portal>
            )}
        </>
    );
};

const CookieConsentComponent: React.FunctionComponent = () => {
    const cookieConsentContext = useCookieConsentContext();

    return cookieConsentContext.view === "intro" ? (
        <div data-testid="cookieContainer" className="self-end w-full max-w-md rounded shadow md:self-auto">
            <CookieIntroView {...cookieConsentContext} />
        </div>
    ) : (
        <div data-testid="cookieContainer" className="self-end w-full max-w-xl rounded shadow md:self-auto min-h-96">
            <CookieSettingsView {...cookieConsentContext} />
        </div>
    );
};

type CookieIntroViewProps = Pick<ReturnType<typeof useCookieConsentContext>, "gotoView" | "saveCookieSettings">;
export const CookieIntroView: React.FunctionComponent<CookieIntroViewProps> = ({ gotoView, saveCookieSettings }) => {
    const onApproveCookies = () => {
        saveCookieSettings({ allowMarketingCookies: true, allowAnalyzeCookies: true });
    };

    const onDenyCookies = () => {
        saveCookieSettings({ allowMarketingCookies: false, allowAnalyzeCookies: false });
    };

    const onGotoSettingsView = () => {
        gotoView("settings");
    };

    return (
        <div
            data-testid="cookieintroview"
            className="fixed bottom-0 left-0 right-0 grid grid-rows-1 px-0 py-4 overflow-y-scroll bg-white md:rounded-lg top-1/2 md:overflow-y-auto md:px-4 md:top-auto md:left-auto md:right-auto md:static"
        >
            <div className="flex flex-col items-center gap-4 p-4 pb-0 bg-white">
                <H4>Dina cookies - Ditt val</H4>
                <p className="block">
                    Med cookies kan vi förbättra din helhetsupplevelse av Movestic, vi använder cookies för
                    funktionalitet, analys och marknadsföring.
                </p>
            </div>
            <div className="flex flex-col gap-4 p-4">
                <PrimaryButton onClick={onApproveCookies}>Acceptera alla cookies</PrimaryButton>
                <SecondaryButton onClick={onDenyCookies}>Endast nödvändiga</SecondaryButton>
                <div onClick={onGotoSettingsView} className="text-center underline cursor-pointer md:mt-4">
                    Anpassa mina cookies och läs mer
                </div>
            </div>
        </div>
    );
};

CookieIntroView.displayName = "CookieIntroView";

type CookieSettignsViewProps = Pick<
    ReturnType<typeof useCookieConsentContext>,
    | "allowMarketingCookies"
    | "setAllowMarketingCookies"
    | "allowAnalyzeCookies"
    | "setAllowAnalyzeCookies"
    | "saveCookieSettings"
>;

export const CookieSettingsView: React.FunctionComponent<CookieSettignsViewProps> = ({
    allowMarketingCookies,
    saveCookieSettings,
    setAllowMarketingCookies,
    setAllowAnalyzeCookies,
    allowAnalyzeCookies,
}) => {
    const onSaveSettings = () => {
        saveCookieSettings({ allowMarketingCookies, allowAnalyzeCookies });
    };

    const onApproveCookies = () => {
        saveCookieSettings({ allowMarketingCookies: true, allowAnalyzeCookies: true });
    };

    return (
        <div
            className="fixed bottom-0 left-0 right-0 grid grid-rows-1 px-4 py-8 overflow-y-scroll bg-white md:rounded-lg top-1/2 md:overflow-y-auto md:px-8 md:top-auto md:left-auto md:right-auto md:static"
            data-testid="cookiesettingsview"
        >
            <div style={{ textAlign: "left" }}>
                <H4>Anpassa cookies</H4>
                <div className="my-4">
                    <p className="block">
                        Vi använder cookies för att förbättra din helhetsupplevelse och för att förstå hur vår sida
                        används. Vissa cookies är nödvändiga för att säkerställa sidans funktionalitet. Du kan när som
                        helst ändra vilka cookies du tillåter oss använda. Ställ in dina cookies nedan.
                    </p>
                    <p className="block">
                        <Link href="/cookies">Läs mer om vår cookie policy!</Link>
                    </p>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex justify-between gap-4">
                        <div>
                            <H5>Nödvändiga</H5>
                            <div className="text-small">
                                Dessa cookies är nödvändiga för att webbplatsen ska fungera korrekt. De används när du
                                använder en funktion på webbplatsen som behöver ett svar, exempelvis när du ställer in
                                cookies, loggar in eller fyller i ett formulär.
                            </div>
                        </div>
                        <div className="text-small text-gray whitespace-nowrap">Alltid på</div>
                    </div>
                    <div className="flex justify-between gap-4">
                        <div>
                            <H5>Marknadsföring</H5>
                            <div className="text-small">
                                Vi samlar in data om hur våra webbplatser och andra digitala enheter används.
                                Informationen används till kundsegmentering för relevant marknadsföring av produkter och
                                tjänster.
                            </div>
                        </div>
                        <div>
                            <Switch checked={allowMarketingCookies} onCheckedChange={setAllowMarketingCookies} />
                        </div>
                    </div>
                    <div className="flex justify-between gap-4">
                        <div>
                            <H5>Analys</H5>
                            <div className="text-small">
                                Cookies för analys tillåter oss att räkna antal besök och hur du hittade till vår
                                webbplats, vilket gör att vi kan mäta och förbättra webbplatsen.
                            </div>
                        </div>
                        <div>
                            <Switch checked={allowAnalyzeCookies} onCheckedChange={setAllowAnalyzeCookies} />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 pb-4 mt-8 md:flex-row">
                    <SecondaryButton onClick={onApproveCookies}>Acceptera alla cookies</SecondaryButton>
                    <PrimaryButton onClick={onSaveSettings}>Spara val</PrimaryButton>
                </div>
            </div>
        </div>
    );
};
CookieSettingsView.displayName = "CookieSettingsView";
