import Link from "next/link";

const BaseButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button
        className={`flex items-center justify-center no-underline text-center box-border cursor-pointer min-w-[85px] min-h-10 leading-6 px-4 py-0 rounded-lg ${className}`} //h-10 whitespace-nowrap leading-8
        {...props}
    >
        {children}
    </button>
);

export const BaseLinkButton = ({
    children,
    className,
    href,
    target,
    ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <Link
        href={href}
        target={target}
        className={`flex items-center justify-center no-underline text-center box-border cursor-pointer min-w-[85px] min-h-10 leading-6 px-4 py-0 rounded-lg ${className}`} //h-10 whitespace-nowrap leading-8
        {...props}
    >
        {children}
    </Link>
);

export const PrimaryButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <BaseButton
        className={`font-bold text-white bg-cherryRed font-proximanova hover:bg-cherryBloom ${className}`}
        {...props}
    >
        {children}
    </BaseButton>
);

export const PrimaryLinkButton = ({ children, className, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <BaseLinkButton
        className={`font-bold text-white bg-cherryRed font-proximanova hover:bg-cherryBloom ${className}`}
        {...props}
    >
        {children}
    </BaseLinkButton>
);

export const SecondaryButton = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <BaseButton
        className={`font-bold border-cherryDark border text-cherryDark font-proximanova hover:bg-cherryDark hover:text-white disabled:(border-cherryDark text-cherryDark opacity-50) ${className}`}
        {...props}
    >
        {children}
    </BaseButton>
);

export const SecondaryLinkButton = ({
    children,
    className,
    ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <BaseLinkButton
        className={`font-bold border-cherryDark border text-cherryDark font-proximanova hover:bg-cherryDark hover:border-cherryDark hover:text-white disabled:(border-cherryDark text-cherryDark opacity-50) ${className}`}
        {...props}
    >
        {children}
    </BaseLinkButton>
);

export const PrimaryLink = ({ children, className, href, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <Link className={`font-bold text-cherryDark no-underline hover:text-cherryRed ${className}`} href={href} {...props}>
        {children}
    </Link>
);

export const PrimaryLinkWhite = ({
    children,
    className,
    href,
    ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <Link className={`font-bold text-white no-underline hover:text-lightGray ${className}`} href={href} {...props}>
        {children}
    </Link>
);
