import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const twMergeConfig = extendTailwindMerge({
    extend: {
        classGroups: {
            "font-size": ["text-small", "text-intro", "text-large", "text-h1", "text-h2", "text-h3", "text-h4"],
        },
    },
});

export function cn(...inputs: ClassValue[]) {
    return twMergeConfig(clsx(inputs));
}
