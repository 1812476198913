// @ts-nocheck
import { DocumentNode } from "graphql";
import {
    ContentQueryQuery,
    ContentQueryQueryVariables,
    NewsListQueryQuery,
    NewsListQueryQueryVariables,
    RelatedNewsQueryQuery,
    RelatedNewsQueryQueryVariables,
    Link as SBLink,
} from "movestic-blocks/components/__generated__/graphql";
import MenuItemLink from "movestic-ui/types/menu/MenuItemLink";
import { SSRConfig } from "next-i18next";
import { IncomingMessage } from "http";
import { Locale } from "date-fns";
import {
    NewsListQuery,
    ContentQuery as Query,
    RelatedNewsQuery,
} from "movestic-blocks/components/graphql/queriesStoryBlok";
import { SiteLocale } from "../../components/graphql/graphql-global-types";
import { httpStatus } from "movestic-ui/utils/httpStatusCodes";
import { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";
import { notFound, redirect, RedirectType } from "next/navigation";

export type StorybokEnvironment = {
    SB_GRAPHQL_ENDPOINT: string | undefined;
    SB_TOKEN: string | undefined;
    SB_TOKEN_VERSION: string | undefined;
};

export async function getStoryblokPage(
    locale: string,
    slug: string[],
    translations?: SSRConfig,
    footerSlug?: string,
    req?: IncomingMessage & { cookies: Partial<{ [key: string]: string }> },
    cookies?: ReadonlyRequestCookies,
) {
    // Note: "req" is object from pages-router (getServerSideProps) while "cookies" is from app-router
    const pageResult = await fetchStoryblokPage(slug.join("/"), { code: locale } as Locale, footerSlug);
    const breadCrumbItems: MenuItemLink[] = createBredcrumbItems(pageResult.breadcrumb.items);

    const page = filterPageAccess(pageResult, req, cookies);

    return {
        props: {
            page: page,
            footer: page.footer?.content,
            breadcrumbs: breadCrumbItems,
            ...translations,
        },
    };
}

const fetchStoryblokPage = async (fullSlug: string, locale: Locale, footerSlug?: string) => {
    const slugQuery = `${locale.code && locale.code !== SiteLocale.sv.toString() ? `${locale.code}/` : ""}${trimSlugQuery(fullSlug)}`;
    const breadCrumbQuery = createBreadcrumbSlugs(fullSlug);

    const pageResult = await runStoryblokQuery<ContentQueryQuery, ContentQueryQueryVariables>(
        Query,
        {
            SB_GRAPHQL_ENDPOINT: process.env.STORYBLOK_GRAPHQL_ENDPOINT,
            SB_TOKEN: process.env.STORYBLOK_TOKEN,
            SB_TOKEN_VERSION: process.env.STORYBLOK_TOKEN_VERSION,
        },
        {
            slug: slugQuery,
            breadcrumbSlugs: breadCrumbQuery || "/",
            footerSlug: footerSlug ? "settings/" + footerSlug : null,
        },
    );

    if (!pageResult?.page) {
        if (fullSlug.startsWith("movestic/")) {
            //A page on /movestic site was not found. Check if there is aregistered redirect for this page in SB
            const redirectPage = await fetchStoryblokPage(
                fullSlug.replace("movestic/", "settings/redirects/"),
                locale,
                "public-footer",
            );
            if (redirectPage?.page?.content?.newPage) {
                redirect(redirectPage.page.content.newPage, RedirectType.replace);
            }
        }

        notFound();
    }

    return pageResult;
};

export const runStoryblokQuery = async <T, VariablesType = {}>(
    q: DocumentNode,
    env: StorybokEnvironment,
    variables?: VariablesType,
) => {
    let cacheSettings = { cache: "no-store" };

    if (process.env.ENVIRONMENT_NAME?.toLowerCase() === "production") {
        cacheSettings = { next: { tags: ["cms"] } };
    }

    const response = await fetch(env.SB_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Token: env.SB_TOKEN,
            Version: env.SB_TOKEN_VERSION,
        },
        body: JSON.stringify({
            query: q?.loc?.source?.body,
            variables,
        }),
        ...cacheSettings,
    });

    const output = await response?.json();
    return output.data as T;
};

export async function getNews(page: number, per_page: number, tag: string, category?: string) {
    const pageResult = await runStoryblokQuery<NewsListQueryQuery, NewsListQueryQueryVariables>(
        NewsListQuery,
        {
            SB_GRAPHQL_ENDPOINT: process.env.STORYBLOK_GRAPHQL_ENDPOINT,
            SB_TOKEN: process.env.STORYBLOK_TOKEN,
            SB_TOKEN_VERSION: process.env.STORYBLOK_TOKEN_VERSION,
        },
        {
            page: page,
            per_page: per_page,
            tag: tag,
            category: category,
        },
    );
    return pageResult ?? {};
}

export async function getRelatedNews(guids: string) {
    const pageResult = await runStoryblokQuery<RelatedNewsQueryQuery, RelatedNewsQueryQueryVariables>(
        RelatedNewsQuery,
        {
            SB_GRAPHQL_ENDPOINT: process.env.STORYBLOK_GRAPHQL_ENDPOINT,
            SB_TOKEN: process.env.STORYBLOK_TOKEN,
            SB_TOKEN_VERSION: process.env.STORYBLOK_TOKEN_VERSION,
        },
        {
            relatedNewsGuids: guids,
        },
    );
    return pageResult ?? {};
}

const createBreadcrumbSlugs = (fullSlug: string, prepend = "") => {
    const slugs = fullSlug.split("/");
    const res: string[] = [];

    let accumulatedSlug = "";
    slugs.forEach((slug, index) => {
        if (index === slugs.length - 1) {
            //We need to add last slug with AND without trailing slash as dont need if there are subpages
            res.push(prepend + accumulatedSlug + slug);
        }

        accumulatedSlug += `${slug}/`;
        res.push(prepend + accumulatedSlug);
    });

    // If fullslug starts with "nyheter/" wee need to process this breadcrumb it differently, as it is root node in Storyblok
    if (res[0] === "nyheter/") {
        res[0] = "movestic/nyheter";
    }

    //Join all slugs and remove trailing slashes
    const finalSlug = res.join(",");
    return trimBreadcrumbQuery(finalSlug);
};

const trimSlugQuery = (slug: string) =>
    slug === "partner/nyheter" ? slug : slug.replace("partner/nyheter", "nyheter");

const trimBreadcrumbQuery = (slug: string) =>
    slug.replace("partner/nyheter/,", "partner/nyheter,").replace("partner/nyheter/", "nyheter/");

const createBredcrumbItems = (items: { full_slug?: string; name?: string }[]) => {
    const breadCrumbItems: MenuItemLink[] = items
        .sort((a, b) => (a.full_slug.length > b.full_slug.length ? 1 : -1))
        .map((x) => ({ url: { pathname: `${x.full_slug.startsWith("/") ? "" : "/"}${x.full_slug}` }, text: x.name }));

    const cleanBreadcrumbs = breadCrumbItems.map((breadcrumb) =>
        breadcrumb.url.pathname.startsWith("/movestic" || "/movesticfonder")
            ? {
                  ...breadcrumb,
                  url: { pathname: breadcrumb.url.pathname.replace(/\/movesticfonder|\/movestic/g, "") },
              }
            : breadcrumb,
    );

    return cleanBreadcrumbs;
};

export const getLink = (link: SBLink) => {
    // @ts-ignore
    let linkUrl = link?.url || link?.story?.url || link?.cachedUrl || link?.cached_url || link?.href;

    if (!linkUrl) {
        return { linkUrl: "", linkTarget: "" };
    }

    if (link?.linktype === "story") {
        linkUrl = !linkUrl.startsWith("/") ? "/" + linkUrl : linkUrl;

        if (linkUrl.startsWith("/movestic/") || linkUrl.startsWith("/movesticfonder/")) {
            linkUrl = linkUrl.replace(/\/movestic\/|\/movesticfonder\//, "/");
        }
    }

    if (link.anchor) {
        linkUrl = `${linkUrl}#${link.anchor}`;
    }

    // @ts-ignore
    const linkTarget = link?.target;
    return { linkUrl, linkTarget };
};

const filterPageAccess = (
    page: ContentQueryQuery,
    req?: IncomingMessage & { cookies: Partial<{ [key: string]: string }> },
    cookies?: ReadonlyRequestCookies,
): ContentQueryQuery => {
    const userRoles: string[] =
        req && req?.cookies
            ? (decodeJwtPayload(req.cookies[process.env.AUTH_TOKEN_COOKIE_NAME])?.roles ?? [])
            : cookies?.has(String(process.env.AUTH_TOKEN_COOKIE_NAME))
              ? (decodeJwtPayload(String(cookies?.get(String(process.env.AUTH_TOKEN_COOKIE_NAME)).value))?.roles ?? [])
              : [];

    //Filter pages
    const userIsInRole = _isInRole(userRoles, page.page.content.visitorgroup);

    if (!userIsInRole) {
        throw httpStatus.FORBIDDEN;
    }

    //Filter content areas
    const contentAreaNames = [
        "contentArea",
        "contentArea1",
        "contentArea2",
        "content1",
        "content2",
        "content3",
        "contentBottom",
        "contentAreaBottom",
    ];

    contentAreaNames.forEach((contentAreaName) => {
        if (!page.page.content[contentAreaName]) {
            return;
        }
        page.page.content[contentAreaName] = page.page.content[contentAreaName]?.filter((x) => {
            if (!x.visitorgroup) {
                return true;
            }

            const hasAccess = _isInRole(userRoles, x.visitorgroup);
            return hasAccess;
        });
    });

    //Filter Richtext
    page.page.content = filterRichtextVisitorGroup(page.page.content, userRoles);
    return page;
};

const filterRichtextVisitorGroup = (rteObj: any, userRoles: string[]): any => {
    const traverse = (obj: any): any => {
        if (obj?.type === "doc" && Array.isArray(obj.content)) {
            const content = [];
            for (let i = 0; i < obj.content.length; i++) {
                if (obj.content[i]?.type !== "blok") {
                    content.push(obj.content[i]);
                    continue;
                }

                obj.content[i].attrs.body = obj.content[i]?.attrs?.body?.filter(
                    (x) => !x.visitorgroup || _isInRole(userRoles, x.visitorgroup),
                );
                content.push(obj.content[i]);
            }

            obj.content = content;
        }

        // Recursively apply the same filter to nested objects
        Object.entries(obj).forEach(([key, val]) => {
            if (typeof val === "object" && val !== null) {
                obj[key] = traverse(val);
            }
        });

        return obj;
    };

    return traverse(rteObj);
};

const _isInRole = (userRoles: string[], roles: string[]) => {
    if (!roles || roles.filter((x) => x).length === 0) {
        return true;
    }
    const intersection = roles.filter((value) => userRoles.includes(value));

    const isInRole = intersection.length > 0;
    return isInRole;
};

const decodeJwtPayload = (jwtContent: string) => {
    if (typeof jwtContent != "string" || jwtContent === undefined) {
        return null;
    }

    const segments = jwtContent.split(".");
    if (segments.length !== 3) {
        throw new Error("Malformed jwt content");
    }
    const payload = segments[1];

    const decoded = base64urlDecode(payload);

    const unicodedString = decodeURIComponent(
        decoded
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join(""),
    );

    return JSON.parse(unicodedString);
};

const base64urlDecode = (str: string) => atob(base64urlUnescape(str));

const base64urlUnescape = (str: string) => {
    if (str.length % 4) {
        str += new Array(5 - (str.length % 4)).join("=");
    }
    return str.replace(/\-/g, "+").replace(/_/g, "/");
};
