/* eslint-disable arrow-body-style */
import { JSXElementConstructor } from "react";
import { StoryMessage } from "movestic-ui/services/NewAgreementClient";

/**
 * @returns The encapsulated generic return type of a promise
 */
export type PromiseReturn<T> = T extends (...args: any[]) => infer R ? (R extends Promise<infer U> ? U : never) : never;

export type Selectable<T> = T & { isSelected: boolean };

export type WithValidation<T> = T & {
    validation?: { isOk: boolean; messages: StoryMessage[] };
};

export type Changeable<T> = T & { isChangeable: boolean };

export const nameOf = <T = never>(propertyName: keyof T) => propertyName;

export type WithConfigurationId<T> = T & { configurationId: string };

export type WithTypedIndexSignature<T, K> = T & { [index in keyof T]: K };

export type WithIndexSignature<T, K> = T & { [index: string]: K };

export type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P> ? P : never;

export type GetComponentProp<
    T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
    K extends keyof React.ComponentProps<T>,
> = Pick<React.ComponentProps<T>, K>[K];

type Pick<T, K extends keyof T> = {
    [P in K]: T[P];
};

/**
 * Infers type(s) from union types based on given function.
 * @param fn a function that returns the value if said type is applicable or undefined / void 0 if not
 * @example const hasNamedProperty = typeGuard((obj: A | B | C) => "named_property" in obj ? obj : undefined)
 */
export function typeGuard<T extends I, I>(fn: (value: I) => T | void) {
    return function (value: I): value is T {
        return fn(value) !== void 0;
    };
}
